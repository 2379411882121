.userButton {
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 16px;
    margin-left: 4px;
    height: 16px;
    border-radius: 50%;
    background: transparent;
    border: 1.5px solid #aaa;
    color: #fff;
    font-size: 8px;
}