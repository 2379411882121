.container {
    height: 100%;
    overflow: hidden;
    flex-shrink: 1;
    flex-basis: 100%;
    position: relative;
    background-color: #1B1D1E;
    display: flex;
    flex-direction: column;

    .image {
        width: 100%;
        height: 300px;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }
}