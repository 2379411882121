.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    
    @media screen and (max-width: 768px) {
        background-color: rgba(44, 44, 44);
    }
    
    .loginPane {
        margin: auto;
        height: 480px;
        display: flex;
        flex-direction: row;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            height: auto;
            margin-top: 0;
            padding: 0;
            width: 100%;
        }

        .form {
            margin: auto;
            width: 300px;
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 40px 60px;
            background-color: rgb(44, 44, 44, 0.72);
            box-sizing: border-box;
            
            @media screen and (max-width: 768px) {
                width: 100%;
                height: auto;
                margin: 0;
            }

            h2 {
                color: #ccc;
                margin-bottom: 40px;
            }

            .formField {
                margin-bottom: 20px;

                input {
                    height: 40px;
                    width: 100%;
                    border-radius: 5px;
                    border: 1px solid #666;
                    background-color: rgb( 88, 88, 88, 0.77 );
                    outline-color: #666;
                    padding: 4px 10px;
                    color: #eee;
                    &::placeholder {
                        color: #999;
                    }
                    box-sizing: border-box;
                }

                button {
                    margin-top:20px;
                    height: 30px;
                    padding: 0 15px;
                    outline-color: #999;
                    color: #ddd;
                    &:hover {
                        cursor: pointer;
                    }
                    &:disabled, &[disabled]{
                        color: #777;
                        &:hover {
                            cursor: default;
                        }
                    }
                }

                .loader {
                    position: relative;
                    top: 3px;
                    margin-left: 15px;
                }
            }

            .errorInfo {
                line-height: 1.35;
                border: 1px solid;
                padding: 17px 17px;
                border-radius: 6px;
                text-align: left;
                font-size: 12px;
                margin-top: 29px;
                color: #eebbbb;
            }
        }

        .pic {
            background-size: cover;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-image: url(../../../public/login.jpg);
            width: 320px;
            box-shadow: 0px 0px 26px 3px rgb(0,0,0,0.25);
            border-right: 1px solid #444;
            z-index: 10;


            @media screen and (max-width: 768px) {
                height: 100px;
                width: 100%;
                border-bottom: 1px solid #444;
                border-right: 0;
            }
        }
    }
}

