.button {
    display: inline-block;
    width: 24px;
    height: 24px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    svg {
        display: block;
        margin: auto;
        width: 12px;
        height: 12px;
        fill: #e1e1e1;
    }
    transition: background-color 0.4s;
    @media (hover: hover) and (pointer: fine) {
        &:not(.disabled):hover {
            cursor: pointer;
            background-color: #3d3d3d;
        }
    }
    &:active {
        background-color: #292929;
    }
    &.disabled {
        svg {
            display: block;
            margin: auto;
            width: 12px;
            height: 12px;
            fill: #373737;
        } 
    }
}